/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import '../pages/blog/blog.css'
import Pagina from '../components/Pagina'
class BlogIndex extends React.Component {
  render () {
    let cat = ''
    const { data } = this.props
    const posts = data.allContentfulBlog.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    return (
      <Fragment>
        <Pagina pagina={data.pagina} />
        <section className="container">
          <nav aria-label="breadcrumb">
          </nav>
          <section>
            <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0 mt-2">
              <h2 className="d-flex flex-column flex-sm-row my-4 my-lg-3 theme-secundary">FIQUE POR <span className="theme-primary ml-sm-2">DENTRO</span></h2>
              <p>A Droga Leste se preocupa com a sua satisfação e o seu bem-estar, afinal você precisa estar bem para cuidar da sua família, não é mesmo? E para viver bem, a prevenção sempre é o melhor caminho. Foi pensando nisso que criamos esse espaço para você, com informações, dicas, curiosidades e tudo o que você precisa para cuidar bem de você e da sua família. Sempre que precisar estaremos lá, seja na prevenção, no alívio ou na cura, conte com a gente, porque quem cuida bem, vive bem!</p>
            </div>
            <div className="row row-cols-1 row-cols-md-3">
              <div className="col">
                <div className="card mb-4 box-shadow">
                  <div className="btn-wrap">
                    <GatsbyImage image={data.bannerMundo.childImageSharp.gatsbyImageData} />
                    <Link to="/blog/beleza/"><button className="btn btn-info">Mundo da Beleza</button></Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 box-shadow">
                  <div className="btn-wrap">
                    <GatsbyImage
                      image={data.bannerPais.childImageSharp.gatsbyImageData}
                      className="card-img-top rounded" />
                    <Link to="/blog/pais-e-filhos/"><button className="btn btn-warning">Pais e Filhos</button></Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 box-shadow">
                  <div className="btn-wrap">
                    <GatsbyImage
                      image={data.bannerSenior.childImageSharp.gatsbyImageData}
                      className="card-img-top rounded" />
                    <Link to="/blog/senior/"><button className="btn btn-primary">Sênior</button></Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0">
            <h2 className="d-flex flex-column flex-sm-row my-4 my-lg-3 theme-secundary">ÚLTIMAS <span className="theme-primary ml-sm-2">PUBLICAÇÕES</span></h2>
            <p>Fique por dentro das últimas dicas que preparamos especialmente para você e sua família!</p>
          </div>
          <div className="row row-cols-1 row-cols-md-3">
            {
              posts.map((post, index) => {
                if (post.node.categoria[0] === 'SÊNIOR') {
                  cat = 'senior'
                }
                if (post.node.categoria[0] === 'MUNDO DA BELEZA') {
                  cat = 'beleza'
                }
                if (post.node.categoria[0] === 'PAIS E FILHOS') {
                  cat = 'pais-e-filhos'
                }
                return (
                  <div key={index} className="col d-flex align-items-stretch">
                    <div className="card mb-4 box-shadow">
                      <GatsbyImage
                        image={post.node.image.localFile.childImageSharp.gatsbyImageData}
                        className="card-img-top"
                        alt={post.node.slug}
                        style={{ height: '200px', fontSize: '18px' }} />
                      <div className="categoria">{post.node.categoria}</div>
                      <div className="card-body">
                        <h3 className="card-title" style={{ color: 'red' }}>{post.node.title}</h3>
                        <p className="card-text text-secundary" style={{ color: 'gray', fontSize: '14px' }}>{post.node.subtitle}</p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <Link to={`/blog/${cat}/${post.node.slug}/`} className="btn btn-block btn-lg orange" >Leia Mais</Link>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: 0,
                paddingRight: '25vw',
                paddingLeft: '25vw',
                paddingBottom: '1vw'
              }}
            >
              {!isFirst && (
                <Link to={`/blog/${prevPage}/`} rel="prev" style={{ color: '#1A3797' }}>
                  «
                </Link>
              )}
              {Array.from({ length: numPages }, (_, i) => (
                <li
                  key={`pagination-number${i + 1}`}
                  style={{
                    margin: 0
                  }}
                >
                  <Link
                    to={`/blog/${i === 0 ? '' : i + 1}/`}
                    style={{
                      padding: 5,
                      textDecoration: 'none',
                      color: i + 1 === currentPage ? '#ffffff' : '#1A3797',
                      background: i + 1 === currentPage ? '#1A3797' : ''
                    }}
                  >
                    {i + 1}
                  </Link>
                </li>
              ))}
              {!isLast && (
                <Link to={`/blog/${nextPage}/`} rel="next" style={{ color: '#1A3797' }}>
                  »
                </Link>
              )}
            </ul>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default BlogIndex
export const pageQuery = graphql`query blogPageQuery($skip: Int!, $limit: Int!) {
  allContentfulBlog(
    sort: {fields: [date], order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        title
        subtitle
        slug
        categoria
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  pagina: contentfulPagina(path: {eq: "/blog/"}) {
    path
    metaTitle
    metaDescription
    tags
  }
  bannerMundo: file(
    relativePath: {eq: "images/imageblog/DrogaLeste-Mundo-da-Beleza.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerPais: file(
    relativePath: {eq: "images/imageblog/DrogaLeste-Pais-e-Filhos.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerSenior: file(relativePath: {eq: "images/imageblog/DrogaLeste-Senior.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
